import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import HeroWithBackground from "../components/HeroWithBackground"
import SimpleIntro from "../components/SimpleIntro"
import ImageGallery from "../components/ImageGallery"
import VideoOurWork from "../components/VideoOurWork"
import MoreWork from "../components/MoreWork"

const Work = props => {
  const location = props.location.pathname

  const workTitle = props.data.work.title
  const workDesc = props.data.work.acf._ikag_ouwo_description
  const heroBg = props.data.work.acf._ikag_ouwo_hero_bg
  const heroImgFluid =
    props.data.work.acf._ikag_ouwo_hero_image.localFile.childImageSharp.fluid
  const heroImgAlt = props.data.work.acf._ikag_ouwo_hero_image.alt_text
  const imgGallery = props.data.work.acf._ikag_ouwo_image_gallery

  const video = props.data.work.acf._ikag_ouwo_video_link
  const videoTitle = props.data.work.acf._ikag_ouwo_video_title

  const randomWork = props.data.randomWork.edges

  return (
    <Layout location={location}>
      <SEO title={workTitle} location={props.location.pathname} />
      <HeroWithBackground
        fluid={heroImgFluid}
        heroAlt={heroImgAlt}
        texture={heroBg}
      />
      <SimpleIntro title={workTitle} description={workDesc} />
      <ImageGallery images={imgGallery} />
      {video && videoTitle && <VideoOurWork video={video} title={videoTitle} />}
      <MoreWork current={props.data.work.wordpress_id} jobs={randomWork} />
    </Layout>
  )
}

export const ourWorkPageQuery = graphql`
  query ourWorkPage($id: Int!) {
    work: wordpressWpOurWork(wordpress_id: { eq: $id }) {
      wordpress_id
      title
      acf {
        _ikag_ouwo_description
        _ikag_ouwo_hero_bg
        _ikag_ouwo_hero_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _ikag_ouwo_image_gallery {
          alt_text
          localFile {
            publicURL
            size
            childImageSharp {
              sizes(maxWidth: 1800) {
                presentationWidth
                presentationHeight
                src
                ...GatsbyImageSharpSizes
              }
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }

        _ikag_ouwo_video_link
        _ikag_ouwo_video_title
      }
    }

    randomWork: allWordpressWpOurWork(sort: { fields: date, order: ASC }) {
      edges {
        node {
          wordpress_id
          title
          id
          slug
          acf {
            _ikag_ouwo_intro_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Work
