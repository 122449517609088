import React from "react"
import styled from "styled-components"

import ReactPlayer from "react-player"
import { medWrapper, colors, headlineOne } from "../Utilities"

const VideoOurWorkSection = styled.section`
  .wrapper {
    ${medWrapper};
  }

  .title {
    width: 100%;
    text-align: center;

    h2 {
      ${headlineOne};
      color: ${colors.colorPrimary};
    }
  }

  .videoContainer {
    width: 100%;
    max-width: 60rem;
    margin: 3rem auto;
  }
`

const VideoOurWork = ({ video, title }) => {
  return (
    <VideoOurWorkSection>
      <div className="wrapper">
        <div className="title">{title && <h2>{title}</h2>}</div>
        <div className="videoContainer">
          <ReactPlayer
            className="video-wrapper"
            id="work-video-player"
            url={video}
            playing={false}
            controls={true}
            width={"100%"}
          />
        </div>
      </div>
    </VideoOurWorkSection>
  )
}

export default VideoOurWork
