import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import {
  headlineThree,
  colors,
  standardWrapper,
  headlineOne,
} from "../Utilities"

const MoreWorkSection = styled.section`
  background: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 30%,
    rgba(157, 201, 228, 0.3) 30%,
    rgba(157, 201, 228, 0.3) 100%
  );

  .wrapper {
    ${standardWrapper};
  }

  .title {
    width: 100%;
    text-align: center;

    h2 {
      ${headlineOne};
      color: ${colors.colorPrimary};
    }
  }

  .moreList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
`

const MoreJobs = styled(Link)`
  width: calc(50% - 4rem);
  margin: 2rem;

  h3 {
    ${headlineThree};
    color: ${colors.colorPrimary};
  }
`

const shuffle = a => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

const MoreWork = ({ current, jobs }) => {
  const moreJobsDisplay = jobs.filter(post => {
    if (post.node.wordpress_id !== current) {
      return post
    }
  })
  const shuffledJobs = shuffle(moreJobsDisplay)
  const displayTwo = [shuffledJobs[0], shuffledJobs[1]]

  return (
    <MoreWorkSection>
      <div className="wrapper">
        <div className="title">
          <h2>More of our work</h2>
        </div>
        <div className="moreList">
          {displayTwo.map((job, index) => {
            return (
              <MoreJobs to={`/portfolio/${job.node.slug}`} key={index}>
                <div>
                  <Img
                    fluid={
                      job.node.acf._ikag_ouwo_intro_image.localFile
                        .childImageSharp.fluid
                    }
                    alt={job.node.acf._ikag_ouwo_intro_image.alt_text}
                  />
                </div>
                <div>
                  <h3>{job.node.title}</h3>
                </div>
              </MoreJobs>
            )
          })}
        </div>
      </div>
    </MoreWorkSection>
  )
}

export default MoreWork
