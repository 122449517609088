import React from "react"
import BackgroundImg from "gatsby-background-image"
import styled from "styled-components"

// import TextureCopper from "../elements/TextureCopper"
// import TextureMarble from "../elements/TextureMarble"
import TextureWood from "../elements/TextureWood"

const HeroImageComponent = styled.div`
  position: relative;
  height: 40rem;

  @media (min-width: 768px) {
    height: 50rem;
  }

  @media (min-width: 1025px) {
    height: 75vh;
    margin-bottom: 10rem;
  }

  .hero-container {
    position: absolute;
    top: 10rem;
    right: 0;
    bottom: 0;
    left: 0;

    max-width: 110rem;
    height: 100%;
    margin: auto;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .hero-image {
    width: 100%;
    height: 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .hero-texture {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 5rem;
    left: 0;
    z-index: -1;

    .gatsby-image-wrapper {
      &::before,
      &::after {
        background-size: auto !important;
        background-repeat: repeat !important;
        background-position: center center !important;
      }
    }
  }
`

const HeroWithBackground = ({ fluid }) => {
  return (
    <HeroImageComponent>
      <div className="hero-container">
        <BackgroundImg className="hero-image" Tag="div" fluid={fluid} />
      </div>
      <div className="hero-texture">
        <TextureWood />
      </div>
    </HeroImageComponent>
  )
}

export default HeroWithBackground
