import React from "react"
import styled from "styled-components"
import { medWrapper, colors } from "../Utilities"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import ImageZoom from "react-medium-image-zoom"

const settings = {
  slidesToShow: 2,
  slidesToScroll: 1,
  dots: true,
  draggable: true,
  infinite: true,
  speed: 300,
  autoplay: false,
  autoplaySpeed: 10000,
  centerMode: true,
  centerPadding: "0px",
  arrows: true,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        centerPadding: "0px",
      },
    },
  ],
}

const ImageGalleryStyled = styled.div`
  .wrapper {
    ${medWrapper};
  }

  .gallerySlider {
    width: 100%;

    .slick-track {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
    }

    .slick-slide {
      &:focus {
        outline: none !important;
      }

      div {
        &:focus {
          outline: none !important;
        }
      }
    }

    .slick-arrow {
      &::before {
        transition: all 0.3s ease-in-out;
        color: ${colors.colorTertiary};
        font-size: 3rem;
      }

      &:hover {
        &::before {
          color: ${colors.colorSecondary};
        }
      }
    }

    .slick-prev {
      left: -0.5rem;
      @media (min-width: 768px) {
        left: -2.5rem;
      }
    }

    .slick-next {
      right: 1rem;
      @media (min-width: 768px) {
        right: -2.5rem;
      }
    }

    ul.slick-dots {
      display: none !important;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;

      @media (min-width: 768px) {
        display: block !important;
      }

      li {
        width: 3rem;
        height: 3rem;
        button {
          width: 3rem;
          height: 3rem;

          &::before {
            display: block;
            width: 3rem;
            height: 3rem;
            font-size: 1.4rem;
            line-height: 2.25;
            color: ${colors.colorSecondary};
            opacity: 0.9;
          }
        }
      }

      li.slick-active {
        button {
          &::before {
            color: ${colors.colorTertiary};
          }
        }
      }
    }
  }
`

const GalleryImage = styled.div`
  align-self: center;
  padding: 5rem;
`

const ImageGallery = ({ images }) => {
  return (
    <ImageGalleryStyled>
      <div className="wrapper">
        <Slider className="gallerySlider" {...settings}>
          {images.map((img, index) => {
            return (
              <GalleryImage key={index}>
                <ImageZoom
                  image={{
                    src: `${img.localFile.childImageSharp.sizes.src}`,
                    alt: `${img.alt_text}`,
                  }}
                  zoomImage={{
                    src: `${img.localFile.childImageSharp.sizes.src}`,
                    alt: `${img.alt_text}`,
                  }}
                />
              </GalleryImage>
            )
          })}
        </Slider>
      </div>
    </ImageGalleryStyled>
  )
}

export default ImageGallery
