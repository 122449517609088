import React from "react"
import styled from "styled-components"
import {
  standardWrapper,
  headlineOne,
  colors,
  headlineThree,
} from "../Utilities"

const SimpleIntroSection = styled.section`
  margin-top: 5rem;

  @media (min-width: 768px) {
    margin-top: 5rem;
  }

  @media (min-width: 1025px) {
    margin-top: 5rem;
  }

  .wrapper {
    ${standardWrapper};
    max-width: 90rem !important;
  }

  .title {
    width: 100%;
    text-align: center;

    h1 {
      ${headlineOne};
      color: ${colors.colorPrimary};
    }
  }

  .description {
    width: 100%;
    text-align: center;

    p {
      ${headlineThree};
    }
  }
`

const SimpleIntro = ({ title, description }) => {
  return (
    <SimpleIntroSection>
      <div className="wrapper">
        <div className="title">
          <h1>{title}</h1>
        </div>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </SimpleIntroSection>
  )
}

export default SimpleIntro
